import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThemeData, ThemeService } from "../services/ThemeService";

const resetTheme = createAsyncThunk(
  "theme/reset",
  async (): Promise<ThemeData> => {
    const theme = ThemeService.getDefault();
    await ThemeService.updateTheme(theme);
    return theme;
  },
);

export { resetTheme };
