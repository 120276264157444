import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThemeData, ThemeService } from "../services/ThemeService";

const initTheme = createAsyncThunk(
  "theme/reset",
  async (): Promise<ThemeData> => {
    await ThemeService.initialize();
    return ThemeService.getDefault();
  },
);

export { initTheme };
