import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThemeData, ThemeService } from "../services/ThemeService";

const updateTheme = createAsyncThunk(
  "theme/update",
  async (options: Partial<ThemeData>) => {
    return await ThemeService.updateTheme(options);
  },
);

export { updateTheme };
