import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  RadioButton,
  RadioGroup,
  Select,
  Option,
  Switch,
  Tab,
  Tabs,
  TextBox,
  ColorPicker,
  NumericTextBox,
  TreeItem,
  TreeView,
  Checkbox,
  MenuList,
  MenuItem,
  ProgressBar,
  AccordionSection,
  Accordion,
} from "@bjornri/react-component-library";
import Menu from "./components/Menu";
import { useSelector } from "react-redux";
import { initTheme, RootState, updateTheme } from "./store";
import useThunk from "./hooks/useThunk";

function App() {
  const theme = useSelector((t: RootState) => t.theme);
  const [doUpdateTheme] = useThunk(updateTheme);
  const [doResetTheme, isResetting] = useThunk(initTheme);

  useEffect(() => {
    doResetTheme();
  }, []);

  if (isResetting) {
    return <div>Loading...</div>;
  }

  return (
    <Grid
      columns="minmax(max-content, 300px) 1fr"
      rows="max-content 1fr"
      gap="5px"
    >
      <Menu />
      <Accordion>
        <AccordionSection expanded title="Global Colors">
          <Grid
            autoRows="max-content"
            gap="5px"
            className="alignSelfStretch"
            style={{ paddingRight: "5px", borderRight: "1px solid lightgray" }}
          >
            <ColorPicker
              label="Page Text Color"
              value={theme.globalColor}
              onChange={(value) => {
                doUpdateTheme({ globalColor: value });
              }}
            />
            <ColorPicker
              label="Page Background Color"
              value={theme.globalBackgroundColor}
              onChange={(value) => {
                doUpdateTheme({ globalBackgroundColor: value });
              }}
            />
            <ColorPicker
              label="Input Text Color"
              value={theme.globalInputColor}
              onChange={(value) => {
                doUpdateTheme({ globalInputColor: value });
              }}
            />
          </Grid>
        </AccordionSection>

        <ColorValues name="secondary" label="Control" />
        <ColorValues name="primary" />
        <ColorValues name="danger" />
        <ColorValues name="warning" />
        <ColorValues name="success" />

        <AccordionSection title="Borders">
          <NumericTextBox
            label="Border radius (px)"
            min={0}
            value={theme.defaultBorderRadius}
            onChange={(value) => {
              doUpdateTheme({ defaultBorderRadius: value });
            }}
          />
        </AccordionSection>
      </Accordion>
      <AllControls />
    </Grid>
  );
}

function ColorValues({ name, label }: { name: string; label?: string }) {
  const theme = useSelector((t: RootState) => t.theme) as unknown as Record<
    string,
    string
  >;

  const [doUpdateTheme] = useThunk(updateTheme);

  const cName = label ?? name[0]?.toUpperCase() + name.slice(1);
  return (
    <AccordionSection title={cName + " Colors"}>
      <Grid
        autoRows="max-content"
        gap="5px"
        className="alignSelfStretch"
        style={{ paddingRight: "5px", borderRight: "1px solid lightgray" }}
      >
        <ColorPicker
          label={`${cName} Text Color`}
          value={theme[`${name}TextColor`]}
          onChange={(value) => {
            doUpdateTheme({ [`${name}TextColor`]: value });
          }}
        />
        <ColorPicker
          label={`${cName} Foreground Color`}
          value={theme[`${name}ForegroundColor`]}
          onChange={(value) => {
            doUpdateTheme({ [`${name}ForegroundColor`]: value });
          }}
        />
        <ColorPicker
          label={`${cName} Background Color`}
          value={theme[`${name}BackgroundColor`]}
          onChange={(value) => {
            doUpdateTheme({ [`${name}BackgroundColor`]: value });
          }}
        />
        <ColorPicker
          label={`${cName} Border Color`}
          value={theme[`${name}BorderColor`]}
          onChange={(value) => {
            doUpdateTheme({ [`${name}BorderColor`]: value });
          }}
        />
        <ColorPicker
          label={`${cName} Outline Color`}
          value={theme[`${name}OutlineColor`]}
          onChange={(value) => {
            doUpdateTheme({ [`${name}OutlineColor`]: value });
          }}
        />
        <ColorPicker
          label={`${cName} Placeholder Color`}
          value={theme[`${name}PlaceholderColor`]}
          onChange={(value) => {
            doUpdateTheme({ [`${name}PlaceholderColor`]: value });
          }}
        />
      </Grid>
    </AccordionSection>
  );
}

function AllControls() {
  const [checked, setChecked] = useState(true);
  const [switched, setSwitched] = useState(true);

  return (
    <Tabs>
      <Tab id="controls" label="All controls">
        <Grid
          autoRows="max-content"
          columns="repeat(5, max-content)"
          gap="10px"
          alignItems="center"
          justifyItems="flex-start"
        >
          <span>Buttons:</span>
          <Button label="Button" />
          <Button label="Button" disabled />
          <div></div>
          <div></div>
          <span>Primary buttons:</span>
          <Button label="Button" primary />
          <Button label="Button" primary disabled />
          <div></div>
          <div></div>
          <span>Danger buttons:</span>
          <Button label="Button" danger />
          <Button label="Button" danger disabled />
          <div></div>
          <div></div>
          <span>Warning buttons:</span>
          <Button label="Button" warning />
          <Button label="Button" warning disabled />
          <div></div>
          <div></div>
          <span>Success buttons:</span>
          <Button label="Button" success />
          <Button label="Button" success disabled />
          <div></div>
          <div></div>

          <span>Controls:</span>
          <TextBox placeholder="Enter text here" />
          <Select placeholder="Select an option">
            <Option value="1" displayValue="Option 1" />
            <Option value="2" displayValue="Option 2" />
            <Option value="3" displayValue="Option 3" />
          </Select>
          <Switch />
          <Checkbox />
          <span>Radio buttons:</span>
          <RadioGroup name="radio-group-1">
            <RadioButton value="female" label="Female" />
            <RadioButton value="male" label="Male" />
            <RadioButton value="other" label="Other" />
          </RadioGroup>
          <div></div>
          <div></div>
          <div></div>
          <span style={{ marginTop: "20px" }}>Animated labels:</span>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <TextBox label="Enter text here" />
          <Select label="Select an option">
            <Option value="1" displayValue="Option 1" />
            <Option value="2" displayValue="Option 2" />
            <Option value="3" displayValue="Option 3" />
          </Select>
          <Switch
            label="Switch with label"
            value={switched}
            onChange={(t) => setSwitched(t)}
          />
          <Checkbox
            label="Checkbox with label"
            value={checked}
            onChange={(t) => setChecked(t)}
          />
          <div></div>

          <div>Progress bar:</div>
          <ProgressBar className="justifySelfStretch" value={50} />
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>

          <div>Indeterminate progress bar:</div>
          <ProgressBar
            className="justifySelfStretch"
            indeterminate={true}
            primary={false}
          />
        </Grid>

        <Tabs style={{ padding: "5px", marginTop: "20px" }}>
          <Tab id="tab1" label="Tab Control">
            <Grid columns="max-content max-content" gap="5px">
              <span>Menu</span>
              <span>TreeView</span>
              <MenuList>
                <MenuItem label="Edit" />
                <MenuItem label="Delete" />
                <MenuItem label="Close" />
              </MenuList>
              <TreeView expandAll={true}>
                <TreeItem id="1" content="Item 1">
                  <TreeItem id="2" content="Item 1.1" />
                  <TreeItem id="3" content="Item 2">
                    <TreeItem id="4" content="Item 2.1" />
                    <TreeItem id="5" content="Item 2.2">
                      <TreeItem id="6" content="Item 2.2.1" />
                      <TreeItem id="7" content="Item 2.2.2" />
                      <TreeItem id="8" content="Item 2.2.3" />
                    </TreeItem>
                    <TreeItem id="9" content="Item 2.3" />
                  </TreeItem>
                  <TreeItem id="10" content="Item 3" />
                </TreeItem>
              </TreeView>
            </Grid>
          </Tab>
          <Tab id="tab2" label="Lorem ipsum">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              maximus dui ut mauris elementum, sit amet laoreet quam blandit.
              Nulla faucibus, libero eu porta suscipit, augue urna tristique
              leo, quis sodales augue augue sed nisi. Ut pretium feugiat porta.
              Cras dapibus erat id arcu porta, nec suscipit leo fringilla.
              Integer eget mauris et massa placerat venenatis ac et lacus. Proin
              porta faucibus fermentum. In bibendum, tortor vitae cursus
              consectetur, ligula augue pretium ante, et tristique felis sapien
              tempor magna. Morbi quis ex aliquam, semper eros vel, molestie
              sapien.
            </p>
          </Tab>
        </Tabs>
      </Tab>
    </Tabs>
  );
}

export default App;
