export interface ThemeData {
  secondaryTextColor: string;
  secondaryForegroundColor: string;
  secondaryBackgroundColor: string;
  secondaryBorderColor: string;
  secondaryOutlineColor: string;
  secondaryPlaceholderColor: string;

  primaryTextColor: string;
  primaryForegroundColor: string;
  primaryBackgroundColor: string;
  primaryBorderColor: string;
  primaryOutlineColor: string;
  primaryPlaceholderColor: string;

  dangerTextColor: string;
  dangerForegroundColor: string;
  dangerBackgroundColor: string;
  dangerBorderColor: string;
  dangerOutlineColor: string;
  dangerPlaceholderColor: string;

  warningTextColor: string;
  warningForegroundColor: string;
  warningBackgroundColor: string;
  warningBorderColor: string;
  warningOutlineColor: string;
  warningPlaceholderColor: string;

  successTextColor: string;
  successForegroundColor: string;
  successBackgroundColor: string;
  successBorderColor: string;
  successOutlineColor: string;
  successPlaceholderColor: string;

  defaultBorderRadius: number;

  globalColor: string;
  globalBackgroundColor: string;
  globalInputColor: string;
}

const sass = new window.Sass();
sass.options({ indentedSyntax: true });

export class ThemeService {
  /**
   * Default theme
   */
  private static defaultTheme: ThemeData = {
    globalColor: "#000000",
    globalBackgroundColor: "#ffffff",
    globalInputColor: "#000000",
  } as ThemeData;

  /**
   * our actual theme
   */
  private static theme: ThemeData = {} as ThemeData;

  /**
   * Theme text without variables
   */
  private static themeText: string = "";

  private static vars: Map<string, string> = new Map();

  /**
   * Get default theme
   */
  static getDefault(): ThemeData {
    return structuredClone(this.defaultTheme);
  }

  /**
   * Load initial theme
   */
  static async initialize(): Promise<void> {
    const response = await fetch("./bundle.sass");
    const theme = await response.text();

    // Remove all variables, so we can easily append them to the start later
    this.themeText = theme.replace(/^\$.*$/gm, (str: string, ..._: any[]) => {
      const i = str.indexOf(":");
      const name = str.substring(0, i);
      const value = str.substring(i + 2);
      const key = name.slice(1);
      this.vars.set(name.slice(1), value);

      // Convert snake-case name to camelCase
      const camelCaseName = name
        .slice(1)
        .replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      (this.defaultTheme as any)[camelCaseName] = createJsValue(key, value);

      return "";
    });

    this.theme = structuredClone(this.defaultTheme);

    await this.rebuildSass();
  }

  /**
   * Update part of the theme
   */
  public static async updateTheme(
    options: Partial<ThemeData>,
  ): Promise<ThemeData> {
    this.theme = { ...this.theme, ...options };
    await this.rebuildSass();
    return this.theme;
  }

  /**
   * Register our function the UI will call to update the theme.
   */
  private static async rebuildSass(): Promise<void> {
    // Replace all values in the theme
    Object.keys(this.theme).forEach((key) => {
      if (key.startsWith("global")) return;

      const sassKey = key.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase());
      this.vars.set(sassKey, (this.theme as any)[key]);
    });

    let varString = "";
    this.vars.forEach((value, key) => {
      varString += `$${key}: ${createSassValue(key, value)}\n`;
    });
    const newTheme = varString + this.themeText;

    // Set global styles
    document.documentElement.style.setProperty(
      "--global-color",
      this.theme.globalColor,
    );
    document.documentElement.style.setProperty(
      "--global-background-color",
      this.theme.globalBackgroundColor,
    );
    document.documentElement.style.setProperty(
      "--global-input-color",
      this.theme.globalInputColor,
    );

    await new Promise<void>((r) => {
      sass.compile(newTheme, { indentedSyntax: true }, (result: any) => {
        if (result.status > 0) {
          console.dir(result);
          return;
        }
        // Remove our existing style element
        document.querySelectorAll("#themeStyle").forEach((e) => e.remove());
        const style = document.createElement("style");
        style.id = "themeStyle";
        style.innerHTML = result.text;
        document.head.appendChild(style);
        r();
      });
    });
  }
}

function createSassValue(key: string, value: any) {
  switch (key) {
    case "default-border-radius":
      return value.toString() + "px";
    default:
      return value;
  }
}

function createJsValue(key: string, value: string): any {
  switch (key) {
    case "default-border-radius":
      return parseInt(value, 10);
    default:
      return value;
  }
}
