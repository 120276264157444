import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./slices/themeSlice";
import { ThemeData } from "./services/ThemeService";

const store = configureStore({
  reducer: {
    theme: themeReducer,
  },
});

export * from "./thunks/initTheme";
export * from "./thunks/resetTheme";
export * from "./thunks/updateTheme";

export interface RootState {
  theme: ThemeData;
}

export default store;
