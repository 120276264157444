import { createSlice } from "@reduxjs/toolkit";
import { resetTheme } from "../thunks/resetTheme";
import { updateTheme } from "../thunks/updateTheme";

const themeSlice = createSlice({
  name: "theme",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetTheme.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(updateTheme.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default themeSlice.reducer;
