import React from "react";
import { useSelector } from "react-redux";
import { Button, Flex, Grower, Spacer } from "@bjornri/react-component-library";
import { resetTheme, RootState } from "../store";
import useThunk from "../hooks/useThunk";

export default function Menu() {
  const theme = useSelector((t: RootState) => t.theme);

  const [doResetTheme] = useThunk(resetTheme);

  function doReset() {
    doResetTheme();
  }

  return (
    <Flex
      style={{
        gridColumn: "span 2",
        borderBottom: "1px solid lightgray",
        paddingBottom: "5px",
        marginBottom: "5px",
      }}
    >
      <span>Theme Builder</span>
      <Grower />
      <Button label="Save" primary={true} onClick={() => console.log(theme)} />
      <Spacer width={5} />
      <Button label="Load" onClick={() => console.log(theme)} />
      <Spacer width={5} />
      <Button label="Reset" onClick={doReset} />
    </Flex>
  );
}
