import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";

type GenericFunction = (...args: any[]) => any;

function useThunk<T extends GenericFunction>(thunk: T): [T, boolean, unknown] {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const runThunk = useCallback(
    (...args: any[]) => {
      setIsLoading(true);
      dispatch(thunk(...args))
        .unwrap()
        .catch((err: any) => setError(err))
        .finally(() => setIsLoading(false));
    },
    [dispatch, thunk],
  );

  return [runThunk as T, isLoading, error];
}

export default useThunk;
